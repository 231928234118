<template>
  <v-row class="match-height">
    <v-col cols="12" md="12">
      <v-card>
        <v-data-table
          dense
          :headers="table1.headers"
          :items="table1.items"
          :page.sync="pageTable1"
          :items-per-page="table1.itemsPerPage"
          :loading="table1.loading"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="font-weight-black">Daftar Grup Arisan</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-dialog v-model="table1.dialog" max-width="400px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on" @click="openDialogAdd()">
                    Tambah
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field v-model="table1.itemEdit.nama" outlined hide-details label="Nama Grup *"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-select
                            :items="table1.jenis_upahs2"
                            outlined
                            dense
                            hide-details
                            v-model="table1.itemEdit.jenis_upah"
                            label="Jenis Upah *"
                          ></v-select>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            type="number"
                            outlined
                            hide-details
                            v-model="table1.itemEdit.iuran"
                            label="Nominal Iuran *"
                          ></v-text-field>
                          <span style="font-weight: bold; padding-top: 30px; color: red">{{
                            formatMoney(table1.itemEdit.iuran)
                          }}</span>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            type="number"
                            outlined
                            hide-details
                            v-model="table1.itemEdit.bunga"
                            label="Bunga (%) *"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" :loading="table1.save_loading" @click="save()"> Simpan </v-btn>
                    <v-btn color="secondary" @click="table1.dialog = false"> Batal </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
            <v-form class="ml-4">
              <v-row>
                <v-col cols="2" md="2">
                  <label for="firstname">Filer Tampilan:</label>
                </v-col>
                <v-col cols="3" md="3">
                  <v-select
                  label="Jenis Upah"
                    :items="table1.jenis_upahs"
                    outlined
                    dense
                    v-model="table1.jenis_upah"
                    @change="optionChange()"
                  ></v-select>
                </v-col>
                <v-col cols="3" md="3">
                  <v-select
                  label="Status"
                    :items="table1.statuss"
                    outlined
                    dense
                    v-model="table1.status"
                    @change="optionChange()"
                  ></v-select>
                </v-col>

                <v-col cols="1" md="1">
                  <v-btn class="primary" small @click="optionChange()">Lihat</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </template>
          <template v-slot:item.tanggal_undian="{ item }">
            {{ formatDate(item.tanggal_undian) }}
          </template>
          <template v-slot:item.iuran="{ item }">
            {{ formatMoney(item.iuran) }}
          </template>
          <template v-slot:item.aksi="{ item }">
            <v-btn class="mr-2" color="primary" small @click="openDialogEdit(item)">
              <v-icon small title="Edit"> {{ icons.mdiPencil }} </v-icon>
            </v-btn>
            <router-link ml-2 :to="{ name: 'arisan_anggota_grup', params: { id: item.id, nama: item.nama } }">
              <v-btn small color="primary"
                ><v-icon small title="Lihat Anggota"> {{ icons.mdiEye }} </v-icon></v-btn
              >
            </router-link>
          </template>

          <template v-slot:no-data>
            <v-btn color="primary" small @click="getTable1()"> Reload </v-btn>
          </template>
        </v-data-table>
        <div class="text-center px-4 py-4">
          <v-row>
            <v-col cols="3">
              <v-select
                label="Jumlah Per Halaman"
                :items="table1.length_menus"
                hide-details="auto"
                outlined
                dense
                v-model="table1.itemsPerPage"
                @change="optionChange()"
              ></v-select>
            </v-col>
            <v-col cols="9">
              <v-pagination v-model="pageTable1" :length="table1.pageCount" :total-visible="3"></v-pagination>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import { mdiPencil, mdiEye } from '@mdi/js'
import { reactive } from '@vue/composition-api'

const apiRoot = process.env.VUE_APP_APIURL
export default {
  setup() {
    const table1 = reactive({
      headers: [
        { text: 'No', value: 'no', sortable: false },
        { text: 'ID', value: 'id', sortable: false },
        { text: 'Jenis Upah', value: 'jenis_upah', sortable: false },
        { text: 'Undian Terakhir', value: 'tanggal_undian', sortable: false },
        { text: 'Nama', value: 'nama', sortable: false },
        { text: 'Iuran', value: 'iuran', sortable: false, align: 'right' },
        { text: 'Bunga', value: 'bunga', sortable: false, align: 'right' },
        { text: 'Aksi', value: 'aksi', sortable: false },
      ],
      items: [],
      loading: false,
      pageCount: 0,
      itemsPerPage: 10,
      search: '',
      jenis_upah: 'SEMUA',
      length_menus: [10, 25, 50],
      jenis_upahs: ['SEMUA', 'HARIAN', 'MINGGUAN', 'BULANAN'],
      jenis_upahs2: ['HARIAN', 'MINGGUAN', 'BULANAN'],
      status: 'AKTIF',
      statuss: ['AKTIF', 'NON-AKTIF'],
      dialog: false,
      editedIndex: -1,
      itemEdit: {
        id: '',
        jenis_upah: '',
        nama: '',
        iuran: 0,
      },
      itemDefault: {
        id: '',
        jenis_upah: '',
        nama: '',
        iuran: 0,
      },
      save_loading: false,
    })
    function getTable1() {
      this.table1.loading = true
      const headers = {
        Authorization: this.token,
      }
      const form = new FormData()
      form.append('page', this.pageTable1)
      form.append('itemsPerPage', this.table1.itemsPerPage)
      form.append('search', this.table1.search)
      form.append('jenis_upah', this.table1.jenis_upah)
      form.append('status', this.table1.status)
      axios
        .post(`${apiRoot}/api/Arisan/getGrupsTable`, form, { headers })
        .then(response => {
          if (response.data.code === 401) {
            this.$store.dispatch('logout', 'Session berakhir!')
          } else if (response.data.code === 200) {
            this.table1.items = response.data.data
            this.table1.pageCount = response.data.length
          } else {
            alert(response.data.message)
          }
          this.table1.loading = false
        })
        .catch(error => {
          alert(error)
          this.table1.loading = false
        })
    }

    return {
      table1,
      getTable1,
      icons: {
        mdiEye,
        mdiPencil,
      },
    }
  },
  data: () => ({
    pageTable1: 1,
  }),
  computed: {
    formTitle() {
      return this.table1.editedIndex === -1 ? 'Form Tambah' : 'Form Edit'
    },
    token() {
      return this.$store.getters.token
    },
    anggotaSelected() {
      return this.$store.getters.anggotaSelected
    },
  },
  watch: {
    pageTable1: {
      handler() {
        this.getTable1()
      },
      deep: true,
    },
    anggotaSelected() {
      this.editedItem.id_anggota = this.anggotaSelected.id
      this.editedItem.kode_anggota = this.anggotaSelected.kode_anggota
      this.editedItem.nama_anggota = this.anggotaSelected.nama
      this.editedItem.jenis_upah_anggota = this.anggotaSelected.jenis_upah
      this.dialogPilihanggota = false
      this.getProgrampinjamans()
    },
  },
  created() {
    this.getTable1()
  },
  methods: {
    openDialogAdd() {
      this.table1.editedIndex = -1
      this.table1.itemEdit = Object.assign({}, this.table1.itemDefault)
    },
    openDialogEdit(item) {
      this.table1.editedIndex = this.table1.items.indexOf(item)
      this.table1.itemEdit = Object.assign({}, item)
      this.table1.dialog = true
    },
    save() {
      if (this.table1.editedIndex > -1) {
        const headers = {
          Authorization: this.token,
        }
        const form = new FormData()
        form.append('id', this.table1.itemEdit.id)
        form.append('nama', this.table1.itemEdit.nama)
        form.append('jenis_upah', this.table1.itemEdit.jenis_upah)
        form.append('iuran', this.table1.itemEdit.iuran)
        form.append('bunga', this.table1.itemEdit.bunga)
        axios
          .post(`${apiRoot}/api/Arisan/saveGrup`, form, { headers })
          .then(response => {
            if (response.data.code === 401) {
              this.$store.dispatch('logout', 'Session berakhir!')
            } else if (response.data.code === 200) {
              alert(response.data.message)
              this.optionChange()
              this.table1.dialog = false
            } else {
              alert(response.data.message)
            }
          })
          .catch(error => {
            alert(error)
          })
      } else {
        this.table1.save_loading = true
        const headers = {
          Authorization: this.token,
        }
        const form = new FormData()
        form.append('nama', this.table1.itemEdit.nama)
        form.append('jenis_upah', this.table1.itemEdit.jenis_upah)
        form.append('iuran', this.table1.itemEdit.iuran)
        form.append('bunga', this.table1.itemEdit.bunga)
        axios
          .post(`${apiRoot}/api/Arisan/saveGrup`, form, { headers })
          .then(response => {
            if (response.data.code === 401) {
              this.$store.dispatch('logout', 'Session berakhir!')
            } else if (response.data.code === 200) {
              this.optionChange()
              this.table1.dialog = false
            } else {
              alert(response.data.message)
            }
            this.table1.save_loading = false
          })
          .catch(error => {
            alert(error)
            this.table1.save_loading = false
          })
      }
    },
    optionChange() {
      this.pageTable1 = 1
      this.getTable1()
    },
  },
}
</script>
